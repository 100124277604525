import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import './views/views-dir.less';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById("root");

if (rootElement) {
    const root = createRoot(rootElement);

    root.render( 
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

serviceWorker.unregister();