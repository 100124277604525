import React from 'react';
import { Row, Col, Divider } from 'antd';

const FooterView = () => {
   
    return (
        <>
        <Divider className="margin-m-v" plain/>
        <Row justify="center" align="middle">
            <Col span={24} className="text-primary font-11 text-center padding-s-v">
                Topline App &copy; 2021 | All Rights Reserved
            </Col>
        </Row>
        </>
    );
}

export default FooterView