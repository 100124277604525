import React, { Component } from "react";
import "./App.css";
import axios from 'axios';
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import AuthService from "./services/api/AuthService";
import PublicRoute from './hoc/PublicRoute';

interface IAppState {
  isLoading: Boolean;
  errorVisible: Boolean;
}
const authService = new AuthService();
const LandingFail = lazyComponentLoader(() => import('./views/landingFail/LandingFailView'));
const Landing = lazyComponentLoader(() => import('./views/landing/LandingView'));
const Login = lazyComponentLoader(() => import('./views/auth/login/LoginView'));
const Forgot = lazyComponentLoader(() => import('./views/auth/forgot/ForgotView'));
const Reset = lazyComponentLoader(() => import('./views/auth/reset/ResetView'));
const Dashboard = lazyComponentLoader(() => import('./views/dashboard/DashboardView'));
const FareMatrix = lazyComponentLoader(() => import('./views/fare-matrix/FareMatrixView'));
const Operators = lazyComponentLoader(() => import('./views/operators/OperatorsView'));
const Riders = lazyComponentLoader(() => import('./views/riders/RidersView'));
const Payables = lazyComponentLoader(() => import('./views/payables/PayablesView'));
const SalesReport = lazyComponentLoader(() => import('./views/sales-report/SalesReportView'));
const Downloadables = lazyComponentLoader(() => import('./views/downloadables/DownloadablesView'));
const Void = lazyComponentLoader(() => import('./views/void/VoidView'));
const Users = lazyComponentLoader(() => import('./views/users/UsersView'));
const TerminalPortManagementView = lazyComponentLoader(() => import('./views/teller-port-management/TerminalPortManagementView'));
const TellerManagementView = lazyComponentLoader(() => import('./views/teller-management/TellerManagementView'));
const SettingsView = lazyComponentLoader(() => import('./views/settings/SettingsView'));

class App extends Component<any, IAppState> {

  constructor(props: any) {
    super(props);
    this.state = {
        isLoading: false,
        errorVisible: true
    };

    const self: any = this;
    axios.interceptors.request.use(function (config) {
      self.setState({ isLoading: true });
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      self.setState({ isLoading: false });
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({ isLoading: false });
      return Promise.reject(error);
    });
  }

  showError(errors: any) {
    let content: any = null;
    if (Array.isArray(errors)) {
      content = (
        <div>
          {errors.map((item: any, index: number) => (
            <div key={Math.random().toString(6)}>{item.message}</div>
          ))}
        </div>
      );
    } else {
      content = errors.message.includes('Network Error') ? 'No Network Connection. Please make sure that Wi-Fi or Mobile Data is turned on, then try again.' : errors.message;
    }

    let showDialog: boolean = true;
    if (errors) {
      if (Array.isArray(errors)) {
        errors.map((value: any) => {
          if (value['context']['key'] === 'access_token' || value['context']['key'] === 'refresh_token') {
            showDialog = false;
          }
        });
      }
    }
    if (showDialog) {
      DialogUtils.error(content);
    }
  }

  render() {
    return (
      <div>
        <Router>
          <Routes>
            <Route path="/success" element={<PublicRoute auth={{ isLoggedIn: () => authService.isLoggedIn() }} component={Landing} />} />
            <Route path="/failed" element={<PublicRoute auth={{ isLoggedIn: () => authService.isLoggedIn() }} component={LandingFail} />} />
            <Route path="/login" element={<PublicRoute auth={{ isLoggedIn: () => authService.isLoggedIn() }} component={Login} />} />
            <Route path="/reset-password/:token" element={<PublicRoute auth={{ isLoggedIn: () => authService.isLoggedIn() }} component={Reset} />} />
            <Route path="/forgot-password" element={<PublicRoute auth={{ isLoggedIn: () => authService.isLoggedIn() }} component={Forgot} />} />
            <Route path="/" element={<LayoutView />}>
              <Route path="dashboard" element={<ProtectedRoute component={Dashboard} />} />
              <Route path="fare-matrix" element={<ProtectedRoute component={FareMatrix} />} />
              <Route path="operators" element={<ProtectedRoute component={Operators} />} />
              <Route path="riders" element={<ProtectedRoute component={Riders} />} />
              <Route path="payables" element={<ProtectedRoute component={Payables} />} />
              <Route path="sales-report" element={<ProtectedRoute component={SalesReport} />} />
              <Route path="downloadables" element={<ProtectedRoute component={Downloadables} />} />
              <Route path="void" element={<ProtectedRoute component={Void} />} />
              <Route path="users" element={<ProtectedRoute component={Users} />} />
              <Route path="terminal-port-management" element={<ProtectedRoute component={TerminalPortManagementView} />} />
              <Route path="teller-management" element={<ProtectedRoute component={TellerManagementView} />} />
              <Route path="settings" element={<ProtectedRoute component={SettingsView} />} />
              <Route
                path="*"
                element={
                  authService.isLoggedIn() ? (
                    <Navigate replace={true} to="/" />
                  ) : (
                    <Navigate replace={true} to="/login" />
                  )
                }
              />
            </Route>
          </Routes>
        </Router>
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );
  }
}

export default App;